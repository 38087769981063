import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock } from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import esoSafetyTable from "../../../assets/ESO-590-safety-small-V1.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Adverse Reactions in Patients With Advanced Esophageal Carcinoma or GEJ Cancer | HCP`,
    keywords: `advanced esophageal carcinoma or gej cancer adverse reactions`,
    description: `Learn about the adverse reactions to KEYTRUDA® (pembrolizumab) in patients with advanced esophageal carcinoma or GEJ cancer. See safety profile in KEYNOTE⁠-⁠590.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/esophageal-carcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA is indicated for the treatment of patients with locally advanced or metastatic esophageal or gastroesophageal junction (GEJ) (tumors with epicenter 1 to 5 centimeters above the GEJ) carcinoma that is not amenable to surgical resection or definitive chemoradiation either: in combination with platinum- and fluoropyrimidine-based chemotherapy, or as a single agent after one or more prior lines of systemic therapy for patients with tumors of squamous cell histology that express programmed death ligand 1 (PD⁠-⁠L1) [combined positive score (CPS) ≥10] as determined by an FDA-approved test."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/esophageal-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

// image alts - so they do not repeat in mobile & desktop
const esoSafetyAlt = "Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA® (pembrolizumab) in KEYNOTE-590"

//from /src/util/data/indications.js
const indicationId = 19;
const jobCode = jobCodes[19].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dosing Modifications', url: '/dosing/dose-modifications/' },
                    ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
    {label: 'Management of Selected Immune-Mediated Adverse Reactions'}, 
    {label: 'Most Common Adverse Reactions with KEYTRUDA'},
    {label: 'Safety Profile in KEYNOTE&#8288;-&#8288;590'}
]

const keyNoteTableFootnotes = [
    {
        label: "c.",
        text: "Graded per NCI CTCAE v4.03."
    },
    {
        label: "d.",
        text: "One fatal event of diarrhea was reported in each arm."
    },
    {
        label: "e.",
        text: "Includes asthenia, fatigue."
    },
]

const keyNoteDefinitions = "NCI-CTCAE = National Cancer Institute-Common Terminology Criteria for Adverse Events."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock>
                                Adverse reactions occurring in patients with esophageal cancer who received KEYTRUDA as a monotherapy were similar to those occurring in patients with melanoma or NSCLC who received KEYTRUDA as a monotherapy.
                            </TextBlock>
                        </PageSection>
                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <DataTable 
                                title="Adverse Reactions in ≥20% of Patients in KEYNOTE&#8288;-&#8288;590" 
                                mobileImage={() => <img src={esoSafetyTable} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={esoSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/eso-590-safety-large-v-1.png" alt={esoSafetyAlt}/>}
                                footnotes={keyNoteTableFootnotes} 
                                definitions={keyNoteDefinitions}/>
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
